<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import type { CtxProduct, CtxProductVariant } from '@containex/portal-backend-api-client';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { computed, watch } from 'vue';
    import { Availability } from '@containex/portal-backend-dto';
    import { getAvailabilityForVariantBasedOnCart } from '@/util/variantAvailability';
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { useCartAction, useCartQuery } from '@/composables/cart';

    const props = defineProps<{
        product: CtxProduct;
        variant?: CtxProductVariant;
        currentRegionId?: string;
        postalCode?: string;
        isRentalDurationSet: boolean;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [variantId: string];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();
    const { cart } = useCartQuery();
    const cartAction = useCartAction();

    const currentVariantAvailability = computed(() => {
        if (props.variant != null) {
            return getAvailabilityForVariantBasedOnCart(props.variant, null, true);
        }
        return Availability.NotAvailable;
    });

    let isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));

    watch(cart, () => {
        isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));
    });

    function addToCart(variantId: string): void {
        if (variantId === '') {
            return;
        }

        emits('add-to-cart', variantId);
    }
</script>
<template>
    <div class="product-details-container">
        <h2 class="text-2xl-bold-line-height-auto">
            {{ product.title }}
        </h2>
        <template v-if="currentVariantAvailability != Availability.NotAvailable">
            <div
                v-if="currentRegionId != null && postalCode != null && (variant?.original_price ?? 0) > 0"
                class="product-details"
            >
                <span class="text-2xl-bold-line-height-auto">
                    <span>
                        <span v-if="!isRentalDurationSet">
                            {{ t('PRODUCT.PRICE_FROM') }}
                        </span>
                        {{
                            t('PRODUCT.PRICE_DAILY', {
                                price: formatPrice(variant?.original_price ?? 0),
                            })
                        }}
                    </span>
                </span>
                <span class="text-sm-regular-line-height-auto exclusive-tax-font-style">{{
                    t('PRODUCT.EXCLUSIVE_TAX')
                }}</span>
            </div>
            <div v-else-if="currentRegionId != null && postalCode != null">
                <span class="text-2xl-bold-line-height-auto">{{ t('PRODUCT.PRICE_ON_REQUEST') }}</span>
            </div>
            <div v-if="currentRegionId != null && postalCode != null && (variant?.original_price ?? 0) > 0">
                <Button
                    icon="pi pi-cart-plus"
                    icon-pos="left"
                    :label="t('CART.ADD_TO_CART')"
                    :disabled="!isVariantAvailableForCart"
                    class="cta-button"
                    rounded
                    data-testid="product-detail-view-add-to-cart-button"
                    @click="addToCart(variant?.id ?? '')"
                />
            </div>
            <div v-else-if="currentRegionId == null || postalCode == null" class="show-price-container">
                <div class="show-price-container-title text-xl-bold-line-height-auto">
                    {{ t('PRODUCT.SHOW_PRICE_TITLE_RENTAL') }}
                </div>
                <div>{{ t('PRODUCT.SHOW_PRICE_DESCRIPTION_RENTAL') }}</div>
                <Button
                    :label="t('PRODUCT.SHOW_PRICE_BUTTON')"
                    class="cta-button cta-button__fit-content"
                    rounded
                    data-testid="product-detail-view-show-price-button"
                    @click.prevent="orderInfoSidebarAction.setIsVisible(true)"
                />
            </div>
        </template>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .product-details-container {
        display: flex;
        flex-direction: column;
        row-gap: main.$spacing-6;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
    }

    .exclusive-tax-font-style {
        color: main.$color-secondary;
    }

    .cta-button {
        width: 100%;
        font-weight: 500;

        &__fit-content {
            width: fit-content;
        }
    }

    @include main.for-breakpoint-sm {
        .cta-button {
            width: unset;
        }
    }

    .show-price-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
        background-color: main.$color-background-lightblue;
        padding: main.$spacing-5;
        border-radius: 4px;

        &-title {
            color: main.$color-primary-darker;
        }
    }
</style>
